export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const CATEGORY_PLAYLIST_CLEAR = 'CATEGORY_PLAYLIST_CLEAR';
export const CATEGORY_PLAYLIST_SET = 'CATEGORY_PLAYLIST_SET';
export const CLEAR_PLAYLIST_VIEW = 'CLEAR_PLAYLIST_VIEW';
export const COPY_FROM_VIEW_AND_PLAY = 'COPY_FROM_VIEW_AND_PLAY';
export const COPY_TO_VIEW = 'COPY_TO_VIEW';
export const FEATURED_SET = 'FEATURED_SET';
export const GENRES_SET = 'GENRES_SET';
export const NEW_RELEASES_SET = 'NEW_RELEASES_SET';
export const PLAY_NEXT_TRACK = 'PLAY_NEXT_TRACK';
export const PLAY_TRACK = 'PLAY_TRACK';
export const PLAYLIST_SET = 'PLAYLIST_SET';
export const RESET_NO_PREVIEW = 'RESET_NO_PREVIEW';
export const SET_PAUSE = 'SET_PAUSE';
export const SET_PLAYLIST_VIEW = 'SET_PLAYLIST_VIEW';
export const STOP_PLAY = 'STOP_PLAY';
export const STOP_TRACK = 'STOP_TRACK';
export const TOKEN_SET = 'TOKEN_SET';
export const TRACK_TIME_UPDATE = 'TRACK_TIME_UPDATE';
export const UNPAUSE = 'UNPAUSE';
